* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.navigation-bg {
	background-color: #f0dbd9;
	}

.banner {
	padding-top: 80px;
}

.schedule, .speakers {
	padding-top: 60px;
	padding-bottom: 60px;
}

.featured-podcasts {
	padding-bottom: 60px;
}
.react-multi-carousel-list h5 {
	font-size: 1rem !important;
}
.react-multi-carousel-item {
	flex: 1 1 auto !important;
	position: relative !important;
	width: 330px !important;
}
.react-multi-carousel-track {
	margin-bottom: 40px !important;
}
.react-multi-carousel-dot button {
	width: 25px !important;
	height: 10px !important;
	border-radius: 10% !important;
	border: none !important;
	background: #f0dbd9 !important;
}
.react-multi-carousel-dot--active button {
	background: #b37671 !important;
}
.speaker {
	padding-top: 0px;
	padding-bottom: 80px;
}

.featured-heading {
	color: #ffffff;
	font-size: 20px;
	padding-bottom: 20px;
	/* padding-top: 50px; */
}

.news-card-heading {
	color: #000000;
	font-size: 16px;
}

.news-card-innertext {
	color: #404040;
	font-size: 13px;
}

.news-section {
	padding-top: 60px;
	padding-bottom: 60px;
}

.session-card-title {
	font-size: 18px;
	font-weight: 600;
}

.newletter {
	color: #000000;
	font-size: 40px;
	text-align: center;
}

.register {
	color: #000000;
	font-size: 30px;
	text-align: center;
}

.newletter-text {
	
	color: #000;
	font-size: 12px;
	text-align: center;
	padding-top: 10px;
}

.rhap_container {
	position: relative !important;
	margin-top: 10px !important;
	float: left !important;
	height: fit-content !important;
	width: 100% !important;
	color: #fff !important;
	display: flex !important;
	box-shadow: none !important;
}

.rhap_main,
.rhap_stacked,
.rhap_current-time,
.rhap_total-time,
.rhap_main-controls-button,
.rhap_play-pause-button,
.rhap_rewind-button,
.rhap_volume-button,
.rhap_volume-bar-area,
.rhap_volume-indicator {
	color: #fff !important;
}

.rhap_progress-indicator {
	background: #fff !important;
}
.rhap_controls-section{
	justify-content: inherit !important;
}
.rhap_volume-container{
	margin-right: 10px;
	color: #fff !important;
}
.rhap_volume-indicator,
.rhap_volume-filled {
	background: #fff !important;
}
.partners-carousel-item {
	display: flex;	
	align-items: center;
	justify-content: center;
	max-width: 180px;
}
.partners-carousel-custom-slider {
	margin-bottom: 0 !important;
}

#scaled-frame {
	width: 1100px;
	height: 1250px;
	border: 0px;
	position: relative;
	left:80px;
	}
  
  #scaled-frame {
	zoom: 0.75;
	transform: scale(0.75);
	transform-origin: scale(0.75);
	-moz-transform: scale(0.75);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0.75);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(0.75);
	-webkit-transform-origin: 0 0;
  }
  
  /* @media screen and (-webkit-min-device-pixel-ratio:0) {
	#scaled-frame {
	  zoom: 1;
	}
  } */