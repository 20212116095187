  @media (min-width : 320px) and (max-width : 350px){

    .download-now{
      position: relative;
      top:-48%;
      left:67%;
      font-weight:700;
      height:auto;
      width:80px;
    } 
}

@media (min-width : 351px) and (max-width : 380px){
  .download-now{
    position: relative;
    top:-48%;
    left:74%;
    font-weight:700;
    height:auto;
    width:80px;
} 
}

@media (min-width : 381px) and (max-width : 400px){
.download-now{
    position: relative;
    top:-48%;
    left:75%;
    font-weight:700;
    height:auto;
    width:80px;
} 

}

@media (min-width : 401px) and (max-width : 480px){
.download-now{
    position: relative;
    top:-50%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
} 

}

@media (min-width : 481px) and (max-width : 500px){
  .download-now{
    position: relative;
    top:-50%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;} 
}

@media (min-width : 501px) and (max-width : 600px){
  .download-now{
    position: relative;
    top:-55%;
    left:75%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
}

@media (min-width : 601px) and (max-width : 767px){
  .download-now{
    position: relative;
    top:-55%;
    left:75%;
    font-weight:700;
    height:auto;
    width:110px;
    } 
  }
  @media (max-width :767px) {
      .xs-mb-4 {
        margin-bottom: 1.5rem;
      }
      .media {
        bottom: 80px !important;
        width: 102px !important;
      }
      .podcast-list, .podcast-banner, .podcasts {
        padding-left: 0;
        padding-right: 0;
      }
      .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
        padding-bottom: 5px;
      }
      .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
        width: 100%;
      }
      .sidenav---sidenav---_2tBP {
        width: 100%;
        bottom: 0;
        height: 82px;
        top: auto;
        padding-top: 0;
      }
      .sidenav---sidenav-nav---3tvij {
        display: flex;
        justify-content: space-around;
      }
      .footer {
        padding-bottom: 80px;
      }
  }
@media (min-width :768px) and (max-width : 991px){
  .download-now{
    position: relative;
    top:-32%;
    left:68%;
    font-weight:700;
    height:auto;
    width:80px;
  } 
}
@media (min-width :768px) {
  .podcast-list, .podcast-banner, .podcasts, .banner, .schedule, .wrapper, .section-bottom {
      padding-left: 130px;
        padding-right: 25px;
        max-width: 1500px;
        margin: auto;
    }
}
@media (max-width :991px) {
  .md-mb-4 {
    margin-bottom: 1.5rem;
  }
}
@media (min-width :992px) and (max-width : 1080px){
  .download-now{
    position: relative;
    top:-45%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1081px) and (max-width : 1199px){
  .download-now{
    position: relative;
    top:-45%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
  } 
}

@media (min-width :1200px) and (max-width : 1300px){
  .download-now{
    position: relative;
    top:-52%;
    left:75%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1301px) and (max-width : 1399px){
  .download-now{
    position: relative;
    top:-52%;
    left:75%;
    font-weight:700;
    height:auto;
    width:100px;
  } 

}
@media (min-width :1400px) and (max-width : 1600px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1601px) and (max-width : 1700px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
}

@media (min-width :1701px) and (max-width : 1920px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
} 
@media (min-width : 320px) and (max-width : 350px){
.welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:10px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:10px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:10px !important;
      }
  .welcome-outer {
        height: 1080px;
      }
  }

  @media (min-width : 351px) and (max-width :480px){
    .welcome-outer {
    height: 860px;
    }

    .welcome{
      font-size: 10pt !important;
      padding-left: 5px !important;
      padding-right:10px !important;
      
      }
  .how-to-register{
        font-size: 10pt !important;
        padding-left: 5px !important;
      padding-right:10px !important;
        
        }
  .register{
        font-size: 10pt !important;
        padding-left: 5px !important;
      padding-right:10px !important;
        }
    
  }


/* @media (min-width : 381px) and (max-width :480px){
  .welcome-outer {
  height: 900px;
  }
  
} */

@media (min-width :481px) and (max-width :650px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }
}

@media (min-width :651px) and (max-width :786px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }

}

@media (min-width :769px) and (max-width :1199px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }
}

@media(min-width: 1024px) and (max-width:1200px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

  
}


@media(min-width: 900px) and (max-width:1023px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

  
}


@media(min-width: 768px) and (max-width:899px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

}


@media(min-width: 600px) and (max-width:767px){
  
  #scaled-frame {
    zoom: 0.55;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1300px;
      border: 0px;
      }

}

@media(min-width: 480px) and (max-width:599px){
  
  #scaled-frame {
    zoom: 0.45;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1600px;
      border: 0px;
      }

}

@media(min-width: 320px) and (max-width:479px){
  
  #scaled-frame {
    zoom: 0.30;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 2400px;
      border: 0px;
      position: relative;
	    left:100px;
      }

}