@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
}
button,
input,
optgroup,
select,
textarea {
	font-family: 'Poppins', sans-serif;
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgb(104, 43, 85);
	font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
	color: rgb(223 91 164);
}
.btn:focus,
.btn:active .navbar-toggler:focus {
	box-shadow: none !important;
	outline: 0;
}
.rhap_container {
	background: #dfb4ae !important;
	position: relative;
	height: auto !important;
	bottom: 0 !important;
	padding: 0;
}
.rhap_time {
	font-size: 12px !important;
	color: #212529 !important;
}
.card-title {
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.2rem;
}
.btn-primary,
.bg-primary {
	background: #cf8881 !important;
}
.btn-primary:focus,
.btn-primary:active {
	box-shadow: none !important;
}
.text-primary {
	color: #cf8881 !important;
}
.App {
	text-align: center;
}
.text-black {
	color: #000 !important;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.btn-download,
.btn-download:hover,
.btn-download:focus,
.btn-download:active {
	background: #fff;
	box-shadow: 0;
	border: 0;
	border-radius: 20px;
	box-shadow: 0 1px 10px rgb(0, 0, 0, 0.2);
	height: 36px;
	font-size: 12px;
	color: #000;
	width: 150px;
	padding-left: 20px;
	font-weight: 600;
	text-align: left;
}
.btn-download img {
	position: absolute;
	top: -8px;
	right: -12px;
	width: 52px;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.sidenav---sidenav-subnav---1EN61 {
	border: 0;
	box-shadow: none;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
	background: rgb(223, 180, 174) !important;
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.sidenav
	.sidenav-subnav
	> .sidenav
	.sidenav-subnavitem
	> .sidenav
	.navitem
	:hover {
	background-color: #dfb4ae !important;
}

/* .bg-audio{
background-color:#CF8881;
color:#ffffff;
position:fixed;
width:380px;
height:100px;
bottom:30px;
left:0;
right:0px;
} */
