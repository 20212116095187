@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.navigation-bg {
	background-color: #f0dbd9;
	}

.banner {
	padding-top: 80px;
}

.schedule, .speakers {
	padding-top: 60px;
	padding-bottom: 60px;
}

.featured-podcasts {
	padding-bottom: 60px;
}
.react-multi-carousel-list h5 {
	font-size: 1rem !important;
}
.react-multi-carousel-item {
	flex: 1 1 auto !important;
	position: relative !important;
	width: 330px !important;
}
.react-multi-carousel-track {
	margin-bottom: 40px !important;
}
.react-multi-carousel-dot button {
	width: 25px !important;
	height: 10px !important;
	border-radius: 10% !important;
	border: none !important;
	background: #f0dbd9 !important;
}
.react-multi-carousel-dot--active button {
	background: #b37671 !important;
}
.speaker {
	padding-top: 0px;
	padding-bottom: 80px;
}

.featured-heading {
	color: #ffffff;
	font-size: 20px;
	padding-bottom: 20px;
	/* padding-top: 50px; */
}

.news-card-heading {
	color: #000000;
	font-size: 16px;
}

.news-card-innertext {
	color: #404040;
	font-size: 13px;
}

.news-section {
	padding-top: 60px;
	padding-bottom: 60px;
}

.session-card-title {
	font-size: 18px;
	font-weight: 600;
}

.newletter {
	color: #000000;
	font-size: 40px;
	text-align: center;
}

.register {
	color: #000000;
	font-size: 30px;
	text-align: center;
}

.newletter-text {
	
	color: #000;
	font-size: 12px;
	text-align: center;
	padding-top: 10px;
}

.rhap_container {
	position: relative !important;
	margin-top: 10px !important;
	float: left !important;
	height: -webkit-fit-content !important;
	height: -moz-fit-content !important;
	height: fit-content !important;
	width: 100% !important;
	color: #fff !important;
	display: flex !important;
	box-shadow: none !important;
}

.rhap_main,
.rhap_stacked,
.rhap_current-time,
.rhap_total-time,
.rhap_main-controls-button,
.rhap_play-pause-button,
.rhap_rewind-button,
.rhap_volume-button,
.rhap_volume-bar-area,
.rhap_volume-indicator {
	color: #fff !important;
}

.rhap_progress-indicator {
	background: #fff !important;
}
.rhap_controls-section{
	justify-content: inherit !important;
}
.rhap_volume-container{
	margin-right: 10px;
	color: #fff !important;
}
.rhap_volume-indicator,
.rhap_volume-filled {
	background: #fff !important;
}
.partners-carousel-item {
	display: flex;	
	align-items: center;
	justify-content: center;
	max-width: 180px;
}
.partners-carousel-custom-slider {
	margin-bottom: 0 !important;
}

#scaled-frame {
	width: 1100px;
	height: 1250px;
	border: 0px;
	position: relative;
	left:80px;
	}
  
  #scaled-frame {
	zoom: 0.75;
	transform: scale(0.75);
	transform-origin: scale(0.75);
	-moz-transform: scale(0.75);
	-moz-transform-origin: 0 0;
	-o-transform: scale(0.75);
	-o-transform-origin: 0 0;
	-webkit-transform: scale(0.75);
	-webkit-transform-origin: 0 0;
  }
  
  /* @media screen and (-webkit-min-device-pixel-ratio:0) {
	#scaled-frame {
	  zoom: 1;
	}
  } */
body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
}
button,
input,
optgroup,
select,
textarea {
	font-family: 'Poppins', sans-serif;
}
.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: rgb(104, 43, 85);
	font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
	color: rgb(223 91 164);
}
.btn:focus,
.btn:active .navbar-toggler:focus {
	box-shadow: none !important;
	outline: 0;
}
.rhap_container {
	background: #dfb4ae !important;
	position: relative;
	height: auto !important;
	bottom: 0 !important;
	padding: 0;
}
.rhap_time {
	font-size: 12px !important;
	color: #212529 !important;
}
.card-title {
	font-size: 0.9rem;
	font-weight: 600;
	line-height: 1.2rem;
}
.btn-primary,
.bg-primary {
	background: #cf8881 !important;
}
.btn-primary:focus,
.btn-primary:active {
	box-shadow: none !important;
}
.text-primary {
	color: #cf8881 !important;
}
.App {
	text-align: center;
}
.text-black {
	color: #000 !important;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.btn-download,
.btn-download:hover,
.btn-download:focus,
.btn-download:active {
	background: #fff;
	box-shadow: 0;
	border: 0;
	border-radius: 20px;
	box-shadow: 0 1px 10px rgb(0, 0, 0, 0.2);
	height: 36px;
	font-size: 12px;
	color: #000;
	width: 150px;
	padding-left: 20px;
	font-weight: 600;
	text-align: left;
}
.btn-download img {
	position: absolute;
	top: -8px;
	right: -12px;
	width: 52px;
}
@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.sidenav---sidenav-subnav---1EN61 {
	border: 0;
	box-shadow: none;
}
.sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T:hover {
	background: rgb(223, 180, 174) !important;
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.sidenav
	.sidenav-subnav
	> .sidenav
	.sidenav-subnavitem
	> .sidenav
	.navitem
	:hover {
	background-color: #dfb4ae !important;
}

/* .bg-audio{
background-color:#CF8881;
color:#ffffff;
position:fixed;
width:380px;
height:100px;
bottom:30px;
left:0;
right:0px;
} */

.rhap_container {
   background-color:#CF8881;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
    position:fixed;
    width:380px;
    height:100px;
    bottom:30px;
    left:0;
    right:0px;
}

.rhap_time {
    color: #ffffff;
    font-size: 16px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
}

.rhap_progress-indicator {
  background: #ffffff;
}

.rhap_progress-filled {
   background: #ffffff;
   
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #ffffff;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.sidenav---sidenav---_2tBP {
    background-color:#dfb4ae;
    top:70px;
    position: fixed !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f{
    font-size: 12px;
    font-weight: 600;
    width: auto !important;
    opacity: 1 !important;
    visibility: visible !important;
    
}
.popover {
  max-width: 400px;
  border: 0;
  box-shadow: none;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
    cursor: default;
    background-color:none !important;
    /* top: 30px; */
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    background-color: #682B55;
    
}
.subtitle {
  font-size: 14px;
}
.session-heading{
    color: #CF8881;
    font-size: 2em;
  }
  .card-img-bg {
    background:#CF8881; 
    padding: 20px 0;
    text-align: center;
  }
  .register-button{
    background-color:#ffffff !important;
    color:#000 !important;
    border:none !important;
    position:absolute;
    top:85%;
    left:0;
    right: 0;
    margin: 0 auto;
    font-weight:600;
    height:40px;
    font-size: .85rem;
  }

 .download-now{
  position: relative;
  top:-52%;
  left:72%;
  font-weight:700;
  height:auto;
  width: 110px;
} 
.media {
  position: fixed !important;
  bottom: 0 !important;
  width: 102px;
}
.media > .sidenav---navitem---9uL5T {
  height: 87px !important;
  justify-content: center;
}
.navbar {
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: #F0DBD9;
  width: 100%;
  z-index: 1010;
}
.sidenav---sidenav-subnav---1EN61 {
  left: 102px;
  bottom: 0;
  box-shadow: none;
  border: 0;
  top: auto;
}
.sidenav---sidenav---_2tBP {
	padding-top: 50px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T{
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  line-height: inherit;
  padding-bottom: 15px;
  padding-top: 5px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.active {
  background: #E4BFBA !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T.active,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:hover, 
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  background: #E4BFBA !important;
}

.banner {
  padding-top: 120px;
}

.podcast-list, .podcast-banner, .podcasts {
  padding-left: 100px;
   padding-right: 100px;
}
.calender{
    width: 20px;
    position: absolute;
    top: -6px;
    right: 15px;
}
.shadow {
  box-shadow: 0 1px 10px rgb(0 0 0 / 20%);
}
.welcome{
padding-left: 40px !important;
padding-right:80px !important;
padding-top:18px !important;
border:none !important;
background:transparent !important;
font-size: 12pt !important;
}

.how-to-register{
  padding-left: 40px !important;
  padding-right:80px !important;
  padding-top:18px !important;
  border:none !important;
  background:transparent !important;
  font-size: 12pt !important;
  }

.register{
    padding-left: 40px !important;
    padding-right:80px !important;
    padding-top:18px !important;
    border:none !important;
    background:transparent !important;
    font-size: 12pt !important;
    }
  .welcome-outer{
    background-color:rgb(255 255 255 / 60%);
    height:500px;
    box-shadow:0 0 6px #bfb3b3;
    padding-top:50px;
    padding-bottom:180px;
    position:relative;
    top:50px;
    border-radius:20px;

  }

  .register-outer{
    background-color:rgb(255 255 255 / 60%);
    height:600px;
    box-shadow: 0 0 6px #bfb3b3;
    padding-top:50px;
    padding-bottom:180px;
    position:relative;
    top:50px;
    border-radius:20px;
  }

  .register-outer-title{
    font-size: 30px;
    text-align: center;

  }


  @media (min-width : 320px) and (max-width : 350px){

    .download-now{
      position: relative;
      top:-48%;
      left:67%;
      font-weight:700;
      height:auto;
      width:80px;
    } 
}

@media (min-width : 351px) and (max-width : 380px){
  .download-now{
    position: relative;
    top:-48%;
    left:74%;
    font-weight:700;
    height:auto;
    width:80px;
} 
}

@media (min-width : 381px) and (max-width : 400px){
.download-now{
    position: relative;
    top:-48%;
    left:75%;
    font-weight:700;
    height:auto;
    width:80px;
} 

}

@media (min-width : 401px) and (max-width : 480px){
.download-now{
    position: relative;
    top:-50%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
} 

}

@media (min-width : 481px) and (max-width : 500px){
  .download-now{
    position: relative;
    top:-50%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;} 
}

@media (min-width : 501px) and (max-width : 600px){
  .download-now{
    position: relative;
    top:-55%;
    left:75%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
}

@media (min-width : 601px) and (max-width : 767px){
  .download-now{
    position: relative;
    top:-55%;
    left:75%;
    font-weight:700;
    height:auto;
    width:110px;
    } 
  }
  @media (max-width :767px) {
      .xs-mb-4 {
        margin-bottom: 1.5rem;
      }
      .media {
        bottom: 80px !important;
        width: 102px !important;
      }
      .podcast-list, .podcast-banner, .podcasts {
        padding-left: 0;
        padding-right: 0;
      }
      .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
        padding-bottom: 5px;
      }
      .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- {
        width: 100%;
      }
      .sidenav---sidenav---_2tBP {
        width: 100%;
        bottom: 0;
        height: 82px;
        top: auto;
        padding-top: 0;
      }
      .sidenav---sidenav-nav---3tvij {
        display: flex;
        justify-content: space-around;
      }
      .footer {
        padding-bottom: 80px;
      }
  }
@media (min-width :768px) and (max-width : 991px){
  .download-now{
    position: relative;
    top:-32%;
    left:68%;
    font-weight:700;
    height:auto;
    width:80px;
  } 
}
@media (min-width :768px) {
  .podcast-list, .podcast-banner, .podcasts, .banner, .schedule, .wrapper, .section-bottom {
      padding-left: 130px;
        padding-right: 25px;
        max-width: 1500px;
        margin: auto;
    }
}
@media (max-width :991px) {
  .md-mb-4 {
    margin-bottom: 1.5rem;
  }
}
@media (min-width :992px) and (max-width : 1080px){
  .download-now{
    position: relative;
    top:-45%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1081px) and (max-width : 1199px){
  .download-now{
    position: relative;
    top:-45%;
    left:70%;
    font-weight:700;
    height:auto;
    width:100px;
  } 
}

@media (min-width :1200px) and (max-width : 1300px){
  .download-now{
    position: relative;
    top:-52%;
    left:75%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1301px) and (max-width : 1399px){
  .download-now{
    position: relative;
    top:-52%;
    left:75%;
    font-weight:700;
    height:auto;
    width:100px;
  } 

}
@media (min-width :1400px) and (max-width : 1600px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:100px;
    } 
}

@media (min-width :1601px) and (max-width : 1700px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
}

@media (min-width :1701px) and (max-width : 1920px){
  .download-now{
    position: relative;
    top:-60%;
    left:78%;
    font-weight:700;
    height:auto;
    width:110px;
  } 
} 
@media (min-width : 320px) and (max-width : 350px){
.welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:10px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:10px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:10px !important;
      }
  .welcome-outer {
        height: 1080px;
      }
  }

  @media (min-width : 351px) and (max-width :480px){
    .welcome-outer {
    height: 860px;
    }

    .welcome{
      font-size: 10pt !important;
      padding-left: 5px !important;
      padding-right:10px !important;
      
      }
  .how-to-register{
        font-size: 10pt !important;
        padding-left: 5px !important;
      padding-right:10px !important;
        
        }
  .register{
        font-size: 10pt !important;
        padding-left: 5px !important;
      padding-right:10px !important;
        }
    
  }


/* @media (min-width : 381px) and (max-width :480px){
  .welcome-outer {
  height: 900px;
  }
  
} */

@media (min-width :481px) and (max-width :650px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }
}

@media (min-width :651px) and (max-width :786px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }

}

@media (min-width :769px) and (max-width :1199px){
  .welcome-outer {
  height: 650px;
  }
  .welcome{
    font-size: 10pt !important;
    padding-left: 5px !important;
    padding-right:30px !important;
    
    }
.how-to-register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      
      }
.register{
      font-size: 10pt !important;
      padding-left: 5px !important;
    padding-right:30px !important;
      }
}

@media(min-width: 1024px) and (max-width:1200px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

  
}


@media(min-width: 900px) and (max-width:1023px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

  
}


@media(min-width: 768px) and (max-width:899px){
  
  #scaled-frame {
    zoom: 0.65;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1200px;
      border: 0px;
      }

}


@media(min-width: 600px) and (max-width:767px){
  
  #scaled-frame {
    zoom: 0.55;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1300px;
      border: 0px;
      }

}

@media(min-width: 480px) and (max-width:599px){
  
  #scaled-frame {
    zoom: 0.45;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 1600px;
      border: 0px;
      }

}

@media(min-width: 320px) and (max-width:479px){
  
  #scaled-frame {
    zoom: 0.30;
    transform: scale(0.65);
    transform-origin: scale(0.75);
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    }

    #scaled-frame {
      width: 1000px;
      height: 2400px;
      border: 0px;
      position: relative;
	    left:100px;
      }

}
