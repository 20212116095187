.rhap_container {
   background-color:#CF8881;
    box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
    position:fixed;
    width:380px;
    height:100px;
    bottom:30px;
    left:0;
    right:0px;
}

.rhap_time {
    color: #ffffff;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
}

.rhap_progress-indicator {
  background: #ffffff;
}

.rhap_progress-filled {
   background: #ffffff;
   
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #ffffff;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.sidenav---sidenav---_2tBP {
    background-color:#dfb4ae;
    top:70px;
    position: fixed !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f{
    font-size: 12px;
    font-weight: 600;
    width: auto !important;
    opacity: 1 !important;
    visibility: visible !important;
    
}
.popover {
  max-width: 400px;
  border: 0;
  box-shadow: none;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T {
    cursor: default;
    background-color:none !important;
    /* top: 30px; */
}

.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
    background-color: #682B55;
    
}
.subtitle {
  font-size: 14px;
}
.session-heading{
    color: #CF8881;
    font-size: 2em;
  }
  .card-img-bg {
    background:#CF8881; 
    padding: 20px 0;
    text-align: center;
  }
  .register-button{
    background-color:#ffffff !important;
    color:#000 !important;
    border:none !important;
    position:absolute;
    top:85%;
    left:0;
    right: 0;
    margin: 0 auto;
    font-weight:600;
    height:40px;
    font-size: .85rem;
  }

 .download-now{
  position: relative;
  top:-52%;
  left:72%;
  font-weight:700;
  height:auto;
  width: 110px;
} 
.media {
  position: fixed !important;
  bottom: 0 !important;
  width: 102px;
}
.media > .sidenav---navitem---9uL5T {
  height: 87px !important;
  justify-content: center;
}
.navbar {
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: #F0DBD9;
  width: 100%;
  z-index: 1010;
}
.sidenav---sidenav-subnav---1EN61 {
  left: 102px;
  bottom: 0;
  box-shadow: none;
  border: 0;
  top: auto;
}
.sidenav---sidenav---_2tBP {
	padding-top: 50px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T{
  background-color: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  line-height: inherit;
  padding-bottom: 15px;
  padding-top: 5px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.active {
  background: #E4BFBA !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T.active,
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:hover, 
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T:focus {
  background: #E4BFBA !important;
}

.banner {
  padding-top: 120px;
}

.podcast-list, .podcast-banner, .podcasts {
  padding-left: 100px;
   padding-right: 100px;
}
.calender{
    width: 20px;
    position: absolute;
    top: -6px;
    right: 15px;
}
.shadow {
  box-shadow: 0 1px 10px rgb(0 0 0 / 20%);
}
.welcome{
padding-left: 40px !important;
padding-right:80px !important;
padding-top:18px !important;
border:none !important;
background:transparent !important;
font-size: 12pt !important;
}

.how-to-register{
  padding-left: 40px !important;
  padding-right:80px !important;
  padding-top:18px !important;
  border:none !important;
  background:transparent !important;
  font-size: 12pt !important;
  }

.register{
    padding-left: 40px !important;
    padding-right:80px !important;
    padding-top:18px !important;
    border:none !important;
    background:transparent !important;
    font-size: 12pt !important;
    }
  .welcome-outer{
    background-color:rgb(255 255 255 / 60%);
    height:500px;
    box-shadow:0 0 6px #bfb3b3;
    padding-top:50px;
    padding-bottom:180px;
    position:relative;
    top:50px;
    border-radius:20px;

  }

  .register-outer{
    background-color:rgb(255 255 255 / 60%);
    height:600px;
    box-shadow: 0 0 6px #bfb3b3;
    padding-top:50px;
    padding-bottom:180px;
    position:relative;
    top:50px;
    border-radius:20px;
  }

  .register-outer-title{
    font-size: 30px;
    text-align: center;

  }

